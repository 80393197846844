<template>
	<div>
		<TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
		
			:slot_table_list="['operation','status','depart_province','destination_province']"
			:submit_preprocessing="submit_preprocessing" ref="list" :rowKey="'id'">
				<template slot="right_btn">
					<a :href="'/manage/crm.trip_pdf/index' | export_form"  target="_blank">
						<a-button  class="a_btn">导出行程</a-button>
					</a>
				</template>
				<template slot="left_btn">
					<div class="left_btn_box">
						<div class="recharge_all">
							<p>全部行程<span class="all_money">{{dataInfo.total}}</span></p>
							<p>未出行行程<span class="all_money">{{dataInfo.none}}</span></p>
							<p>进行中行程<span class="all_money">{{dataInfo.in_progress}}</span></p>
						</div>
					</div>
				</template>
			<template slot="operation" slot-scope="data">
				<span>
					<a @click="details(data.record)">查看</a>
				</span>
			</template>
			<template slot="depart_province" slot-scope="data">
				<span>
					{{data.record.depart_province+data.record.depart_city+data.record.depart_address}}
				</span>
			</template>
			<template slot="destination_province" slot-scope="data">
				<span>
					{{data.record.destination_province+data.record.destination_city+data.record.destination_city}}
				</span>
			</template>
		
		</TableList>
		<EditPop @handleSubmit="submit_company" :width="600" class='edit_pop' :form_data="form_company" :visible.sync="visible_data">
			<template slot="test">
				 <a-radio-group v-model="value_down" >
				    <a-radio :style="radioStyle" :value="1">
				     按人员导出 <span class="value_down_info">所有行程导出一个表格(pdf格式)</span>
				    </a-radio>
				    <a-radio :style="radioStyle" :value="2">
				     按行程导出 <span class="value_down_info">同一个人多条行程导出一个表格，多个人多个表格(pdf格式)</span>
				    </a-radio>
				  </a-radio-group>
			</template>
		</EditPop>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import {
		getTripPlace,countTrip
	} from "@/api/evection.js";

	const columns = [
		{
				title: "行程类型",
				dataIndex: "type_name"
			},
		{
			title: "员工姓名",
			dataIndex: "username"
		},
		{
			title: "部门",
			dataIndex: "department_name"
		},
		{
			title: "状态",
			dataIndex: "status_name",
			scopedSlots: {
				customRender: "status_name"
			}
		},
		{
			title: "出发时间",
			dataIndex: "start_time"
		},
		{
			title: "出发地",
			dataIndex: "depart_province",
			scopedSlots: {
				customRender: "depart_province"
			}
		},
		{
			title: "到达时间",
			dataIndex: "end_time",
			scopedSlots: {
				customRender: "end_time"
			}
		}, {
			title: "到达地",
			dataIndex: "destination_province",
			scopedSlots: {
				customRender: "destination_province"
			}
		},
		{
			title: "操作",
			dataIndex: "operation",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];

	export default {
		name: "Index",
		components: {
			EditPop,
			Form,
			TableList,
		},
		data() {
			return {
				dataInfo:{},
				value_down:'',
				 radioStyle: {
				        display: 'block',
				        height: '30px',
				        lineHeight: '30px',
				      },
				visible_data:false,
				config: this.$config,
				get_table_list: getTripPlace,
				submit_preprocessing: {
					array_to_string: ['department_id', 'group_id']
				},
				columns,
				selectedRowKeys: [],
				loading: false,
				visible: false,
				transfer_type: 'jump_customer',
				transfer_name: 'uid',
				transfer_list: {
					uid: '',
					to_uid: ''
				},
				transfer_visible: false,
				user_visible: false,
				form_company:{
					title: "导出方式",
					show_submit_btn: false,
					show_close_btn: false,
					close_reset_btn: false,
					list: [
						{
							type:'slot',
							name:'test',
							title:'请选择导出方式'
						},

					]
				},
				form_data_seo: {
					list: [{
							type: "text",
							name: "keyword",
							title: "姓名",
							placeholder: "员工姓名",
							options: {}
						},
						{
							type: "tree-select",
							name: "department_id",
							title: "部门",
							options: {},
							treeData: [],
							multiple: true
						},
						{
							type: "select",
							name: "status",
							title: "行程状态",
							mode: "default",
							options: {},
							list: [
								{
								
									key: "0",
									value: "未出行"
								},
								{
									key: "1",
									value: "已出行"
								},
								{
									key: "-1",
									value: "取消出行"
								},
								
							]
						},
						{
							type: "range_date",
							name: "range_date",
							title: "出发时间",
							options: {},
							start: {
								name: 'start_time_first'
							},
							end: {
								name: 'start_time_second'
							},
						},
						

					],
					...this.$config.form_data_seo
				},
				customer_ids: '',
				select_user: [],
			};
		},

		computed: {
			hasSelected() {
				return this.selectedRowKeys.length > 0;
			},
			rowSelection() {
				const {
					selectedRowKeys
				} = this;
				return {
					selectedRowKeys,
					onChange: (selectedRowKeys, selectedRows) => {
						this.selectedRowKeys = selectedRowKeys;
						this.customer_ids = selectedRowKeys.join(',')
					},
					hideDefaultSelections: true,
				};
			}
		},
		async created() {
			Promise.all([this.$method.get_department(), this.$method.get_group(), this.$method.get_level()]).then(
				res => {
					this.form_data_seo.list.forEach(item => {
						if (item.name == "department_id") {
							item.treeData = res[0];
						}
						if (item.name == "group_id") {
							item.list = res[1];
						}
						if (item.name == "level_id") {
							item.list = res[2];
						}
					});
				});

			this.getcountTrip()
			
		},
		methods: {
			getcountTrip(){
				countTrip().then(res=>{
					this.dataInfo =  res.data.data
				})
			},
			submit_company(){},
			open_download(){
				this.visible_data=  true
			},
			details(data) {
				this.$router.push({
					path:'trip_details',
					query:{
						id:data.id,
						plan_id:data.plan_id,
						type:data.type,
					}
				})
			},

			add() {
				this.$router.push("/customer/index_edit");
			},
			edit(key) {
				this.$router.push("/customer/index_edit?id=" + key.id);
			},
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");
	.primary_btn {
		color: #00A3FF;
	}
	.value_down_info {
		display: inline-block;
		margin-left: 10px;
		font-size: 12px;
		color: #999;
	}
	.recharge_all {
		font-size: 16px;
		display: flex;
		color: #333;
	
		.all_money {
			font-size: 20px;
			color: #FF0000;
			margin-left: 10px;
			margin-right: 30px;
			display: inline-block;
		}
	
	}
</style>
